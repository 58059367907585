import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _26b8f4b8 = () => interopDefault(import('../client/pages/kits/index.vue' /* webpackChunkName: "pages/kits/index" */))
const _7d70a8a8 = () => interopDefault(import('../client/pages/lojas/index.vue' /* webpackChunkName: "pages/lojas/index" */))
const _4a6f8848 = () => interopDefault(import('../client/pages/mapa-do-site/index.vue' /* webpackChunkName: "pages/mapa-do-site/index" */))
const _7c498cdc = () => interopDefault(import('../client/pages/minha-conta/index.vue' /* webpackChunkName: "pages/minha-conta/index" */))
const _d7bd41b6 = () => interopDefault(import('../client/pages/motos/index.vue' /* webpackChunkName: "pages/motos/index" */))
const _479c6e84 = () => interopDefault(import('../client/pages/reset/index.vue' /* webpackChunkName: "pages/reset/index" */))
const _5cb810cb = () => interopDefault(import('../client/pages/veiculos/index.vue' /* webpackChunkName: "pages/veiculos/index" */))
const _4a47c422 = () => interopDefault(import('../client/pages/checkout/carrinho/index.vue' /* webpackChunkName: "pages/checkout/carrinho/index" */))
const _761c37e4 = () => interopDefault(import('../client/pages/checkout/confirmacao/index.vue' /* webpackChunkName: "pages/checkout/confirmacao/index" */))
const _29b45c38 = () => interopDefault(import('../client/pages/checkout/pagamento/index.vue' /* webpackChunkName: "pages/checkout/pagamento/index" */))
const _c7d86f52 = () => interopDefault(import('../client/pages/minha-conta/enderecos/index.vue' /* webpackChunkName: "pages/minha-conta/enderecos/index" */))
const _23523dbd = () => interopDefault(import('../client/pages/minha-conta/meus-dados/index.vue' /* webpackChunkName: "pages/minha-conta/meus-dados/index" */))
const _3e9db04a = () => interopDefault(import('../client/pages/minha-conta/pedidos/index.vue' /* webpackChunkName: "pages/minha-conta/pedidos/index" */))
const _7eda35fe = () => interopDefault(import('../client/pages/usuario/cadastro/index.vue' /* webpackChunkName: "pages/usuario/cadastro/index" */))
const _0e678177 = () => interopDefault(import('../client/pages/usuario/login/index.vue' /* webpackChunkName: "pages/usuario/login/index" */))
const _e6ed514a = () => interopDefault(import('../client/pages/checkout/confirmacao/pix.vue' /* webpackChunkName: "pages/checkout/confirmacao/pix" */))
const _19dc4560 = () => interopDefault(import('../client/pages/produto/_id/_.vue' /* webpackChunkName: "pages/produto/_id/_" */))
const _19b5cefd = () => interopDefault(import('../client/pages/kit/_id/_.vue' /* webpackChunkName: "pages/kit/_id/_" */))
const _dc19aa02 = () => interopDefault(import('../client/pages/lista/_.vue' /* webpackChunkName: "pages/lista/_" */))
const _aef873f4 = () => interopDefault(import('../client/pages/informacoes-gerais/_.vue' /* webpackChunkName: "pages/informacoes-gerais/_" */))
const _1c63dc7e = () => interopDefault(import('../client/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/kits",
    component: _26b8f4b8,
    name: "kits"
  }, {
    path: "/lojas",
    component: _7d70a8a8,
    name: "lojas"
  }, {
    path: "/mapa-do-site",
    component: _4a6f8848,
    name: "mapa-do-site"
  }, {
    path: "/minha-conta",
    component: _7c498cdc,
    name: "minha-conta"
  }, {
    path: "/motos",
    component: _d7bd41b6,
    name: "motos"
  }, {
    path: "/reset",
    component: _479c6e84,
    name: "reset"
  }, {
    path: "/veiculos",
    component: _5cb810cb,
    name: "veiculos"
  }, {
    path: "/checkout/carrinho",
    component: _4a47c422,
    name: "checkout-carrinho"
  }, {
    path: "/checkout/confirmacao",
    component: _761c37e4,
    name: "checkout-confirmacao"
  }, {
    path: "/checkout/pagamento",
    component: _29b45c38,
    name: "checkout-pagamento"
  }, {
    path: "/minha-conta/enderecos",
    component: _c7d86f52,
    name: "minha-conta-enderecos"
  }, {
    path: "/minha-conta/meus-dados",
    component: _23523dbd,
    name: "minha-conta-meus-dados"
  }, {
    path: "/minha-conta/pedidos",
    component: _3e9db04a,
    name: "minha-conta-pedidos"
  }, {
    path: "/usuario/cadastro",
    component: _7eda35fe,
    name: "usuario-cadastro"
  }, {
    path: "/usuario/login",
    component: _0e678177,
    name: "usuario-login"
  }, {
    path: "/checkout/confirmacao/pix",
    component: _e6ed514a,
    name: "checkout-confirmacao-pix"
  }, {
    path: "/produto/:id?/*",
    component: _19dc4560,
    name: "produto-id-all"
  }, {
    path: "/kit/:id?/*",
    component: _19b5cefd,
    name: "kit-id-all"
  }, {
    path: "/lista/*",
    component: _dc19aa02,
    name: "lista-all"
  }, {
    path: "/informacoes-gerais/*",
    component: _aef873f4,
    name: "informacoes-gerais-all"
  }, {
    path: "/",
    component: _1c63dc7e,
    name: "index"
  }],

  parseQuery: function(query) {
			const qs = require("qs")

			return qs.parse(query)
		},
  stringifyQuery: function(query) {
			const qs = require("qs")

			const result = qs.stringify(query)

			return result ? `?${result}` : ""
		},
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
